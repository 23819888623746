define("@cloudcreativity/ember-quill/components/quill/toolbar/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="ql-formats" ...attributes>
    {{yield}}
  </span>
  
  */
  {
    "id": "D+JhEp4J",
    "block": "[[[11,1],[24,0,\"ql-formats\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/group.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});