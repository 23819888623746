define("ember-on-helper/helpers/on", ["exports", "ember-on-helper/utils/event-listener"], function (_exports, _eventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__counts = __counts;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * These are private API and only used for testing instrumentation.
   */
  var adds = 0;
  var removes = 0;

  function __counts() {
    return {
      adds: adds,
      removes: removes
    };
  }

  var assertValidEventOptions = true
  /* DEBUG */
  && function () {
    var ALLOWED_EVENT_OPTIONS = ['capture', 'once', 'passive'];

    var joinOptions = function joinOptions(options) {
      return options.map(function (o) {
        return "'".concat(o, "'");
      }).join(', ');
    };

    return function (eventOptions, eventName) {
      var invalidOptions = Object.keys(eventOptions).filter(function (o) {
        return !ALLOWED_EVENT_OPTIONS.includes(o);
      });
      (true && !(invalidOptions.length === 0) && Ember.assert("ember-on-helper: Provided invalid event options (".concat(joinOptions(invalidOptions), ") to '").concat(eventName, "' event listener. Only these options are valid: ").concat(joinOptions(ALLOWED_EVENT_OPTIONS)), invalidOptions.length === 0));
    };
  }();

  function setupListener(eventTarget, eventName, callback, eventOptions) {
    if (true
    /* DEBUG */
    ) assertValidEventOptions(eventOptions, eventName);
    (true && !(eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function') && Ember.assert("ember-on-helper: '".concat(eventTarget, "' is not a valid event target. It has to be an Element or an object that conforms to the EventTarget interface."), eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function'));
    (true && !(typeof eventName === 'string' && eventName.length > 1) && Ember.assert("ember-on-helper: '".concat(eventName, "' is not a valid event name. It has to be a string with a minimum length of 1 character."), typeof eventName === 'string' && eventName.length > 1));
    (true && !(typeof callback === 'function') && Ember.assert("ember-on-helper: '".concat(callback, "' is not a valid callback. Provide a function."), typeof callback === 'function'));
    adds++;
    (0, _eventListener.addEventListener)(eventTarget, eventName, callback, eventOptions);
    return callback;
  }

  function destroyListener(eventTarget, eventName, callback, eventOptions) {
    if (eventTarget && eventName && callback) {
      removes++;
      (0, _eventListener.removeEventListener)(eventTarget, eventName, callback, eventOptions);
    }
  }

  var _default = Ember.Helper.extend({
    eventTarget: null,
    eventName: undefined,
    callback: undefined,
    eventOptions: undefined,
    compute: function compute(_ref, eventOptions) {
      var _ref2 = _slicedToArray(_ref, 3),
          eventTarget = _ref2[0],
          eventName = _ref2[1],
          callback = _ref2[2];

      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
      this.eventTarget = eventTarget;
      this.callback = setupListener(this.eventTarget, eventName, callback, eventOptions);
      this.eventName = eventName;
      this.eventOptions = eventOptions;
    },
    willDestroy: function willDestroy() {
      this._super();

      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
    }
  });

  _exports.default = _default;
});