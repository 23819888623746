define("ember-validated-form/components/validated-input", ["exports", "uuid", "ember-validated-form/-private/themed-component", "ember-validated-form/templates/components/validated-input"], function (_exports, _uuid, _themedComponent, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component wraps form inputs.
   *
   * It can be used in a two-way-binding style like
   * {{validated-input model=model name='firstName'}} (model will be updated)
   *
   * or in a one-way-binding style
   * {{validated-input model=model name='firstName' on-update=(action "update"}}
   * (update action is called, model is not updated)
   *
   * @class validated-input
   * @export default
   */
  var _default = Ember.Component.extend({
    layout: _validatedInput.default,
    tagName: "",
    dirty: false,
    required: false,
    type: "text",
    validateBeforeSubmit: true,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.defineProperty(this, "_val", Ember.computed("value", "model.".concat(this.name), "name", function () {
        return this.value || this.get("model.".concat(this.name));
      }));
    },
    inputId: Ember.computed(function () {
      return (0, _uuid.v4)();
    }),
    errors: Ember.computed("_val", "name", function () {
      var errors = this.get("model.error.".concat(this.name, ".validation")) || [];

      if (!Array.isArray(errors)) {
        return [errors];
      }

      return errors;
    }),
    isValid: Ember.computed("showValidity", "errors.[]", function () {
      return this.showValidity && !this.errors.length;
    }),
    isInvalid: Ember.computed("showValidity", "errors.[]", function () {
      return this.showValidity && !!this.errors.length;
    }),
    renderComponent: (0, _themedComponent.default)("validated-input/render"),
    labelComponent: (0, _themedComponent.default)("validated-input/label"),
    hintComponent: (0, _themedComponent.default)("validated-input/hint"),
    errorComponent: (0, _themedComponent.default)("validated-input/error"),
    showValidity: Ember.computed("validateBeforeSubmit", "dirty", "submitted", function () {
      return this.submitted || this.validateBeforeSubmit && this.dirty;
    }),
    actions: {
      setDirty: function setDirty() {
        this.set("dirty", true);
      },
      update: function update(value) {
        if (this["on-update"]) {
          this["on-update"](value, this.model);
        } else {
          this.set("model.".concat(this.name), value);
        }
      }
    }
  });

  _exports.default = _default;
});