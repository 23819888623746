define("ember-validated-form/-private/themed-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = function _default(component) {
    return Ember.computed({
      get: function get() {
        var parts = component.split("/");

        var _parts = _toArray(parts),
            componentNameParts = _parts.slice(1);

        if (this.get("overrideComponents.".concat(componentNameParts))) {
          return this.get("overrideComponents.".concat(componentNameParts));
        }

        var config = Ember.getOwner(this).resolveRegistration("config:environment")["ember-validated-form"] !== undefined ? Ember.getOwner(this).resolveRegistration("config:environment")["ember-validated-form"] : {};
        var theme = config.theme;
        var defaultComponent = Ember.get(config, "defaults.".concat(componentNameParts.join("/")));
        var name = parts.pop();
        var basePath = parts.join("/");
        return defaultComponent || (theme ? "".concat(basePath, "/-themes/").concat(theme, "/").concat(name) : "".concat(basePath, "/").concat(name));
      },
      set: function set(key, value) {
        if (!this.get("overrideComponents")) {
          this.set("overrideComponents", {});
        }

        this.set("overrideComponents.".concat(key), value);
        return value;
      }
    });
  };

  _exports.default = _default;
});