define("ember-validated-form/components/validated-input/render", ["exports", "ember-validated-form/-private/themed-component", "ember-validated-form/templates/components/validated-input/render"], function (_exports, _themedComponent, _render) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _render.default,
    selectComponent: (0, _themedComponent.default)("validated-input/types/select"),
    radioGroupComponent: (0, _themedComponent.default)("validated-input/types/radio-group"),
    checkboxComponent: (0, _themedComponent.default)("validated-input/types/checkbox"),
    textareaComponent: (0, _themedComponent.default)("validated-input/types/textarea"),
    inputComponent: (0, _themedComponent.default)("validated-input/types/input")
  });

  _exports.default = _default;
});