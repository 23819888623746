define("ember-file-upload/components/file-dropzone/component", ["exports", "ember-file-upload/components/base-component", "ember-file-upload/components/file-dropzone/template", "ember-file-upload/system/data-transfer", "ember-file-upload/system/uuid", "ember-file-upload/system/parse-html", "ember-file-upload/system/drag-listener"], function (_exports, _baseComponent, _template, _dataTransfer, _uuid, _parseHtml, _dragListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DATA_TRANSFER = 'DATA_TRANSFER' + _uuid.default.short();

  var supported = function () {
    return typeof window !== 'undefined' && window.document && 'draggable' in document.createElement('span');
  }();

  var dragListener = new _dragListener.default();
  /**
    `FileDropzone` is a component that will allow users to upload files by
     drag and drop.
  
    ```hbs
    <FileDropzone @name="photos" as |dropzone queue|>
      {{#if dropzone.active}}
        {{#if dropzone.valid}}
          Drop to upload
        {{else}}
          Invalid
        {{/if}}
      {{else if queue.files.length}}
        Uploading {{queue.files.length}} files. ({{queue.progress}}%)
      {{else}}
        <h4>Upload Images</h4>
        <p>
          {{#if dropzone.supported}}
            Drag and drop images onto this area to upload them or
          {{/if}}
          <FileUpload @name="photos"
                      @accept="image/*"
                      @multiple=true
                      @onFileAdd={{this.uploadImage}}>
            <a id="upload-image" tabindex=0>Add an Image.</a>
          </FileUpload>
        </p>
      {{/if}}
    </FileDropzone>
    ```
  
    ```js
    import Controller from '@ember/controller';
  
    export default Ember.Route.extend({
      actions: {
        uploadImage(file) {
         file.upload(URL, options).then((response) => {
            ...
         });
        }
      }
    });
    ```
  
    @class FileDropzone
    @type Ember.Component
    @yield {Hash} dropzone
    @yield {boolean} dropzone.supported
    @yield {boolean} dropzone.active
    @yield {boolean} dropzone.valid
    @yield {Queue} queue
   */

  var _default = _baseComponent.default.extend({
    layout: _template.default,
    supported: supported,
    active: false,
    valid: true,

    /**
      Whether multiple files can be selected when uploading.
      @argument multiple
      @type {boolean}
     */
    multiple: null,

    /**
      The name of the queue to upload the file to.
       @argument name
      @type {string}
      @required
     */
    name: null,

    /**
      If set, disables input and prevents files from being added to the queue
       @argument disabled
      @type {boolean}
      @default false
     */
    disabled: false,

    /**
      A list of MIME types / extensions to be accepted by the input
      @argument accept
      @type {string}
     */
    accept: null,

    /**
      `onFileAdd` is called when a file is selected.
       When multiple files are selected, this function
      is called once for every file that was selected.
       @argument onFileAdd
      @type {function}
      @required
     */
    onFileAdd: null,
    onfileadd: Ember.computed.deprecatingAlias('onFileAdd', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),

    /**
      `onDragEnter` is called when a file has entered
      the dropzone.
       @argument onDragEnter
      @type {function}
     */
    onDragEnter: null,
    ondragenter: Ember.computed.deprecatingAlias('onDragEnter', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),

    /**
      `onDragLeave` is called when a file has left
      the dropzone.
       @argument onDragLeave
      @type {function}
     */
    onDragLeave: null,
    ondragleave: Ember.computed.deprecatingAlias('onDragLeave', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),

    /**
      `onDrop` is called when a file has been dropped.
       @argument onDrop
      @type {function}
     */
    onDrop: null,
    ondrop: Ember.computed.deprecatingAlias('onDrop', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),

    /**
      Whether users can upload content
      from websites by dragging images from
      another webpage and dropping it into
      your app. The default is `false` to
      prevent cross-site scripting issues.
       @argument allowUploadsFromWebsites
      @type {boolean}
      @default false
     */
    allowUploadsFromWebsites: false,

    /**
      This is the type of cursor that should
      be shown when a drag event happens.
       Corresponds to `dropEffect`.
       This is one of the following:
       - `copy`
      - `move`
      - `link`
       @argument cursor
      @type {string}
      @default null
     */
    cursor: null,
    didInsertElement: function didInsertElement() {
      this._super();

      dragListener.addEventListeners("#".concat(this.elementId), {
        dragenter: Ember.run.bind(this, 'didEnterDropzone'),
        dragleave: Ember.run.bind(this, 'didLeaveDropzone'),
        dragover: Ember.run.bind(this, 'didDragOver'),
        drop: Ember.run.bind(this, 'didDrop')
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      dragListener.removeEventListeners("#".concat(this.elementId));
    },
    isAllowed: function isAllowed() {
      var _Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment'),
          environment = _Ember$getOwner$resol.environment;

      return environment === 'test' || this[DATA_TRANSFER].source === 'os' || this.allowUploadsFromWebsites;
    },
    didEnterDropzone: function didEnterDropzone(evt) {
      var dataTransfer = _dataTransfer.default.create({
        queue: this.queue,
        source: evt.source,
        dataTransfer: evt.dataTransfer,
        itemDetails: evt.itemDetails
      });

      this[DATA_TRANSFER] = dataTransfer;

      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.cursor;
        Ember.set(this, 'active', true);
        Ember.set(this, 'valid', dataTransfer.valid);

        if (this.onDragEnter) {
          this.onDragEnter(dataTransfer);
        }
      }
    },
    didLeaveDropzone: function didLeaveDropzone(evt) {
      Ember.set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);

      if (this.isAllowed()) {
        if (evt.dataTransfer) {
          evt.dataTransfer.dropEffect = this.cursor;
        }

        if (this.onDragLeave) {
          this.onDragLeave(this[DATA_TRANSFER]);
          this[DATA_TRANSFER] = null;
        }

        if (this.isDestroyed) {
          return;
        }

        Ember.set(this, 'active', false);
      }
    },
    didDragOver: function didDragOver(evt) {
      Ember.set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);

      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.cursor;
      }
    },
    didDrop: function didDrop(evt) {
      var _this = this;

      Ember.set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);

      if (!this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.cursor;
        this[DATA_TRANSFER] = null;
        return;
      } // Testing support for dragging and dropping images
      // from other browser windows


      var url;
      var html = this[DATA_TRANSFER].getData('text/html');

      if (html) {
        var parsedHtml = (0, _parseHtml.default)(html);
        var img = parsedHtml.getElementsByTagName('img')[0];

        if (img) {
          url = img.src;
        }
      }

      if (url == null) {
        url = this[DATA_TRANSFER].getData('text/uri-list');
      }

      if (url) {
        var image = new Image();

        var _url$split$slice = url.split('/').slice(-1),
            _url$split$slice2 = _slicedToArray(_url$split$slice, 1),
            filename = _url$split$slice2[0];

        image.crossOrigin = 'anonymous';

        image.onload = function () {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);

          if (canvas.toBlob) {
            canvas.toBlob(function (blob) {
              var _this$queue$_addFiles = _this.queue._addFiles([blob], 'web'),
                  _this$queue$_addFiles2 = _slicedToArray(_this$queue$_addFiles, 1),
                  file = _this$queue$_addFiles2[0];

              Ember.set(file, 'name', filename);
            });
          } else {
            var binStr = atob(canvas.toDataURL().split(',')[1]);
            var len = binStr.length;
            var arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }

            var blob = new Blob([arr], {
              type: 'image/png'
            });
            blob.name = filename;

            var _this$queue$_addFiles3 = _this.queue._addFiles([blob], 'web'),
                _this$queue$_addFiles4 = _slicedToArray(_this$queue$_addFiles3, 1),
                file = _this$queue$_addFiles4[0];

            Ember.set(file, 'name', filename);
          }
        };
        /* eslint-disable no-console */


        image.onerror = function (e) {
          console.log(e);
        };
        /* eslint-enable no-console */


        image.src = url;
      }

      if (this.onDrop) {
        this.onDrop(this[DATA_TRANSFER]);
      } // Add file(s) to upload queue.


      Ember.set(this, 'active', false);

      this.queue._addFiles(this[DATA_TRANSFER].files, 'drag-and-drop');

      this[DATA_TRANSFER] = null;
    }
  });

  _exports.default = _default;
});